/**
 * Remommend to include `product.showcase` in the product object
 * Otherwise, it will not account for the transaction queue / max registrant
 */
function isProductAvailable(product) {
  if (product.showcase?.isSoldOut) {
    return false
  }

  const isProductStartDateValid = product.startSaleAt
    ? Date.now() > Date.parse(product.startSaleAt)
    : true
  const isProductEndDateValid = product.endSaleAt
    ? Date.now() < Date.parse(product.endSaleAt)
    : true

  return product.isOnGoing && isProductStartDateValid && isProductEndDateValid
}

const productHelper = {
  isProductAvailable
}

export default productHelper
