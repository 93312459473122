// react
import { useEffect } from 'react'
// services
import { activityService } from '~/services'
// hooks
import useAuth from './useAuth'

// ----------------------------------------------------------------------

const pageTypePayloadMap = Object.freeze({
  Page: { type: 'page-view', objectType: 'page' },
  ContentPage: { type: 'content-page-view', objectType: 'product' },
  PromoPage: { type: 'promo-page-view', objectType: 'product' }
})

// ----------------------------------------------------------------------

/**
 * Hook to track page view
 * @param {string} libraryId - library id for page that will be tracked
 * @param {'Page', 'ContentPage', 'PromoPage'} pageType - page type that will be tracked
 * @param {string} pageOrProductId - page or product id that will be tracked
 * @returns {void}
 */
export default function useTrackPageView(libraryId, pageType, pageOrProductId) {
  const { isInitialized, isAuthenticated, getToken } = useAuth()

  useEffect(() => {
    // incomplete params, skip
    if (!libraryId || !pageOrProductId) return

    // auth not initialized, skip
    if (!isInitialized) return

    // unknown page type, warn and skip
    if (!Object.keys(pageTypePayloadMap).includes(pageType)) {
      console.warn('Unknown page type is being rendered!')

      return
    }

    const trackPageView = async () => {
      try {
        const token = isAuthenticated ? await getToken() : null

        await activityService.postSave(
          {
            library: libraryId,
            object: pageOrProductId,
            ...pageTypePayloadMap[pageType]
          },
          token
        )
      } catch (_) {
        // silent error since this is non critical action
      }
    }

    setTimeout(() => trackPageView(), 3000)
  }, [
    libraryId,
    pageType,
    pageOrProductId,
    isInitialized,
    isAuthenticated,
    getToken
  ])
}
