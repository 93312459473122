// @mui
import { Stack } from '@mui/material'
// component
import NativeImage from '~/components/NativeImage'
// utils
import fallbackImage from '~/libs/fallbackImage'

// component specific
import { ContentPageSection } from '../components'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {Object[]} props.images
 * @param {string} props.images[].imageURL
 * @returns {JSX.Element}
 */
export default function ContentPageListImage({ title, images }) {
  return (
    <ContentPageSection title={title}>
      <Stack spacing={0.5}>
        {images.map(({ imageURL }) => (
          <div key={`image-${imageURL}`}>
            <NativeImage
              src={imageURL ?? fallbackImage.grey300Pixel}
              alt={`image list for ${title}`}
            />
          </div>
        ))}
      </Stack>
    </ContentPageSection>
  )
}
