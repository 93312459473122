// @mui
import { Grid } from '@mui/material'
// component
import NativeImage from '~/components/NativeImage'
import ImageLinkOverlay from '~/components/ImageLinkOverlay'
import SquareImageWrapper from '~/components/SquareImageWrapper'
// utils
import fallbackImage from '~/libs/fallbackImage'

// component specific
import { PromoPageSection } from '../components'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {array} props.images
 * @param {string} props.images[].imageURL
 * @param {string} props.images[].linkURL
 * @returns {JSX.Element}
 */
export default function PromoPageGridImage({ title, images }) {
  return (
    <PromoPageSection title={title}>
      <Grid
        container
        spacing={0.5}
      >
        {images.map(({ imageURL, linkURL }, imageNo) => (
          <Grid
            item
            xs={4}
            key={`image-${imageNo}`}
          >
            {linkURL ? (
              <ImageLinkOverlay
                href={linkURL}
                isCompact='true'
              >
                <SquareImageWrapper>
                  <NativeImage
                    src={imageURL ?? fallbackImage.grey300Pixel}
                    alt={`${title}, image ${imageNo}`}
                  />
                </SquareImageWrapper>
              </ImageLinkOverlay>
            ) : (
              <SquareImageWrapper>
                <NativeImage
                  src={imageURL ?? fallbackImage.grey300Pixel}
                  alt={`${title}, image ${imageNo}`}
                />
              </SquareImageWrapper>
            )}
          </Grid>
        ))}
      </Grid>
    </PromoPageSection>
  )
}
