// @mui
import { Button } from '@mui/material'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} props.buttonLabel
 * @param {string} props.buttonLinkURL
 * @returns {JSX.Element}
 */
export default function ContentPageLinkButton({ buttonLabel, buttonLinkURL }) {
  return (
    <Button
      variant='contained'
      color='warning'
      size='large'
      LinkComponent='a'
      href={buttonLinkURL}
      target='_blank'
      sx={{ textAlign: 'center' }}
    >
      {buttonLabel}
    </Button>
  )
}
