// import
import NextLink from 'next/link'
// @mui
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Grid,
  Stack,
  Typography
} from '@mui/material'
// components
import NativeImage from '~/components/NativeImage'
import { GridCarousel } from '~/components/carousel'
import TextMaxLine from './TextMaxLine'
// libs
import { formatDate } from '~/libs/dateHelper'
import { formatIDR } from '~/libs/numberFormatter'
// paths
import { PATH_PAGE } from '~/routes/paths'

import { IMAGE_CDN_URL } from '~/config'

// i18n
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {'purchase'|'showcase'} props.type
 * //
 * @param {string} props.libraryId
 * //
 * @param {string} props.creatorName
 * @param {string} props.creatorAvatarURL
 * @param {string} props.creatorPath
 * @param {number} props.creatorPurchaseCount
 * //
 * @param {string} props.productId
 * @param {string} props.productName
 * @param {string} props.productDescription
 * @param {Object[]} props.productImages
 * @param {string} props.productImages[].imageURL
 * @param {number} props.productPrice
 * //
 * @param {number} props.date either created date or payment date, depend on type
 * //
 * @param {boolean} props.isShowCreator
 * @returns {JSX.Element}
 */
export default function ContentCard({
  type = 'purchase',
  //
  libraryId,
  //
  creatorName,
  creatorAvatarURL,
  creatorPurchaseCount,
  //
  productId,
  productName,
  productDescription,
  productImages,
  productPrice,
  productPriceBeforeDiscount,
  productIsPromoCodeAvailable,
  //
  date,
  //
  isShowCreator = true
}) {
  const { _ } = useLingui()

  return (
    <Card>
      {isShowCreator && (
        <CardActionArea
          LinkComponent={NextLink}
          href={PATH_PAGE.redirect.mainPage(libraryId)}
          sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
        >
          <CardContent sx={{ padding: 1 }}>
            <Stack
              direction='row'
              spacing={1.5}
              sx={{ width: '100%', alignItems: 'center' }}
            >
              <Avatar
                sx={{ width: '2.25rem', height: '2.25rem' }}
                src={
                  creatorAvatarURL
                    ? !creatorAvatarURL.startsWith('https://') &&
                      !creatorAvatarURL.startsWith('http://')
                      ? `${IMAGE_CDN_URL}/${creatorAvatarURL}`
                      : creatorAvatarURL
                    : null
                }
              />

              <Box sx={{ flexGrow: 1, height: '100%' }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 'small' }}>
                  {creatorName ?? 'Clicky Creator'}
                </Typography>

                {creatorPurchaseCount > 0 && (
                  <Typography
                    sx={{ fontSize: 'small', color: 'text.secondary' }}
                  >
                    {creatorPurchaseCount} {_(msg`pembeli`)}
                  </Typography>
                )}
              </Box>

              <Button
                LinkComponent={NextLink}
                href={PATH_PAGE.redirect.mainPage(libraryId)}
                variant='outlined'
                size='small'
                sx={{
                  alignSelf: 'center',
                  paddingY: '0.05rem',
                  paddingX: '0.5rem',
                  minWidth: 'unset',
                  fontSize: '0.75rem'
                }}
              >
                {_(msg`Kunjungi`)}
              </Button>
            </Stack>
          </CardContent>
        </CardActionArea>
      )}

      <GridCarousel
        isEnableDotNav={false}
        isEnableEdgeNav={productImages.length > 1}
      >
        {productImages.map(({ imageURL }) => (
          <Box
            key={`image-${imageURL}}`}
            sx={{
              position: 'relative',
              width: '100%',
              paddingTop: '66.6%'
            }}
          >
            <NativeImage
              src={imageURL}
              alt={`Highlight image for ${productName}`}
              sx={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
            />
          </Box>
        ))}
      </GridCarousel>

      <CardContent sx={{ paddingTop: 1.5 }}>
        <div>
          <TextMaxLine
            line={2}
            sx={{
              fontWeight: 'bold',
              fontSize: '1.1rem',
              lineHeight: 1.25,
              minHeight: '2.5em'
            }}
          >
            {productName}
          </TextMaxLine>

          {type === 'purchase' && (
            <TextMaxLine
              line={1}
              sx={{
                minHeight: '1.5em',
                fontSize: 'small'
              }}
            >
              {productDescription}
            </TextMaxLine>
          )}
        </div>

        <Box sx={{ marginTop: 1 }}>
          <Typography sx={{ fontSize: 'small', color: 'text.secondary' }}>
            {type === 'showcase' ? _(msg`Dibuat Pada`) : _(msg`Dibeli Pada`)}:{' '}
            {formatDate(date)}
          </Typography>

          <Grid
            container
            spacing={1}
            alignItems='center'
          >
            <Grid
              item
              xs
            >
              <Typography sx={{ fontSize: 'small', color: 'text.secondary' }}>
                {_(msg`Harga`)}:{' '}
                {productPrice === 0 ? _(msg`Gratis`) : formatIDR(productPrice)}{' '}
                {productPriceBeforeDiscount && (
                  <Typography
                    component='span'
                    variant='shy'
                    sx={{
                      textDecoration: 'line-through',
                      color: 'text.secondary',
                      fontSize: '0.7rem'
                    }}
                  >
                    {formatIDR(productPriceBeforeDiscount)}
                  </Typography>
                )}
              </Typography>
            </Grid>
            <Grid
              item
              // xs='auto'
            >
              {productIsPromoCodeAvailable && (
                <Chip
                  color='error'
                  size='small'
                  label='Promo'
                />
              )}
            </Grid>
          </Grid>
        </Box>

        <Button
          variant='contained'
          fullWidth
          sx={{ marginTop: 1.5 }}
          //
          LinkComponent={NextLink}
          href={
            type === 'showcase'
              ? PATH_PAGE.redirect.promoPage(productId)
              : PATH_PAGE.redirect.contentPage(productId)
          }
        >
          {type === 'showcase' ? _(msg`Lihat`) : _(msg`Buka`)}
        </Button>
      </CardContent>
    </Card>
  )
}
