// next
import NextLink from 'next/link'
// @mui
import { Box, Button, Typography } from '@mui/material'
import { CategoryOutlined } from '@mui/icons-material'
// components
import { GridCarousel } from '~/components/carousel'
import ContentCard from '~/components/ContentCard'
// paths
import { PATH_PAGE } from '~/routes/paths'
import useResponsive from '~/hooks/useResponsive'

// i18n
import { useLingui } from '@lingui/react'
import { msg } from '@lingui/macro'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {Object} props.library
 * @param {Object[]} props.relatedProductList
 * @returns {JSX.Element}
 */
export default function RelatedProduct({
  library: _library,
  relatedProductList
}) {
  const mdUp = useResponsive('up', 'md')

  // i18n
  const { _ } = useLingui()

  return (
    <div>
      <Typography variant='hx'>{_(msg`Temukan produk serupa`)}</Typography>

      {!relatedProductList?.length > 0 ? (
        <Button
          fullWidth
          variant='outlined'
          size='large'
          //
          LinkComponent={NextLink}
          href={PATH_PAGE.showcase}
          //
          sx={{ marginTop: 1 }}
          startIcon={<CategoryOutlined />}
        >
          {_(msg`Kunjungi Showcase`)}
        </Button>
      ) : (
        <GridCarousel
          columns={{ xs: 1, md: 2 }}
          peek={mdUp ? 0.2 : 0.33}
          sx={{ marginTop: 1 }}
        >
          {relatedProductList.map(
            ({
              _id,
              name,
              description,
              highlightImages,
              price,
              library: rpLibrary,
              createdAt
            }) => (
              <Box key={_id}>
                <ContentCard
                  type='showcase'
                  libraryId={rpLibrary}
                  //
                  productId={_id}
                  productName={name}
                  productDescription={description}
                  productImages={highlightImages}
                  productPrice={price}
                  //
                  date={createdAt}
                  //
                  isShowCreator={false}
                />
              </Box>
            )
          )}
        </GridCarousel>
      )}
    </div>
  )
}
