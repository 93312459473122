// next
import NextLink from 'next/link'
// @mui
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
// components
import NativeImage from '~/components/NativeImage'
import { GridCarousel } from '~/components/carousel'
// utils
import fallbackImage from '~/libs/fallbackImage'

// subcomponents
import { ContentPageSection } from '../components'

// ----------------------------------------------------------------------

const ImageLink = styled(NextLink)({
  display: 'block',
  cursor: 'pointer'
})

const Image = styled(NativeImage)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  borderRadius: theme.spacing(2)
}))

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {string} props.description
 * @param {Array<{ imageURL: string, linkURL: string }>} props.images
 * @returns {JSX.Element}
 */
export default function ContentPageImageCarousel({
  title,
  description,
  images
}) {
  return (
    <ContentPageSection title={title}>
      <GridCarousel
        isPersistEdgeNavOnMobile
        isEnableDotNav={images.length > 1}
        isEnableEdgeNav={images.length > 1}
      >
        {images.map(({ imageURL, linkURL }, imageNo) =>
          linkURL ? (
            <ImageLink
              key={`image-no-${imageNo}`}
              href={linkURL}
            >
              <Image
                src={imageURL ?? fallbackImage.grey300Pixel}
                alt={`Image ${imageNo} for ${title}`}
              />
            </ImageLink>
          ) : (
            <div key={`image-no-${imageNo}`}>
              <Image
                src={imageURL ?? fallbackImage.grey300Pixel}
                alt={`Image ${imageNo} for ${title}`}
              />
            </div>
          )
        )}
      </GridCarousel>

      <Typography>{description}</Typography>
    </ContentPageSection>
  )
}
