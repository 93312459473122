import { useState } from 'react'
// @mui
import {
  Box,
  ListItemIcon,
  ListItemButton,
  ListItem,
  ListItemText
} from '@mui/material'
import {
  // HeadsetOutlined,
  OpenInNew,
  AttachFileRounded,
  InsertLinkRounded
} from '@mui/icons-material'
// components
import TextWithSoftBreaks from '~/components/TextWithSoftBreaks'
import AudioPlayer from '~/components/AudioPlayer'
import { ContentPageSection } from '../components'
import AccessFileButton from '~/components/AccessFileButton'
import NativeImage from '~/components/NativeImage'

// ----------------------------------------------------------------------
/**
 * @param {Object} props
 * @param {string} props.title
 * @param {string} props.description
 * @param {Object} props.page
 * @returns {JSX.Element}
 */
export default function ContentPageAudioPlaylist({
  title,
  description,
  page,
  list
}) {
  const firstAudioItem = list.find(
    (item) => item.type === 'audio' && item.audio
  )

  const [audioSrc, setAudioSrc] = useState(
    firstAudioItem ? firstAudioItem.audio : null
  )
  const [isAudioPlaying, setIsAutoPlayAudio] = useState(false)

  const handleAudioSrcChange = (audio) => {
    setAudioSrc(audio)
    setIsAutoPlayAudio(true)
  }

  return (
    <ContentPageSection title={title}>
      <Box>
        {description && (
          <Box sx={{ marginBottom: 1 }}>
            <TextWithSoftBreaks>{description}</TextWithSoftBreaks>
          </Box>
        )}
      </Box>

      {audioSrc && (
        <Box marginBottom={2}>
          <AudioPlayer
            fileId={audioSrc}
            accessContext={{
              contextType: 'ContentPage',
              contextId: page._id
            }}
            autoPlay={Boolean(isAudioPlaying)}
          />
        </Box>
      )}

      <Box style={{ maxHeight: '19rem', overflowY: 'auto' }}>
        {list.map((item, index) => {
          return (
            <div key={index}>
              <ListItem
                divider
                disablePadding
              >
                {item.type === 'audio' ? (
                  <AudioPlaylistAudioItem
                    audioSrc={audioSrc}
                    item={item}
                    onSelectAudio={handleAudioSrcChange}
                    index={index}
                  />
                ) : item.type === 'link' ? (
                  <AudioPlaylistLinkItem
                    item={item}
                    index={index}
                  />
                ) : item.type === 'file' ? (
                  <AudioPlaylistFileItem
                    item={item}
                    index={index}
                    page={page}
                  />
                ) : null}
              </ListItem>
            </div>
          )
        })}
      </Box>
    </ContentPageSection>
  )
}

// ----------------------------------------------------------------------

/**
 * Renders an audio item in the audio playlist.
 *
 * @param {Object} props - The component props.
 * @param {string} props.audioSrc - The current audio source.
 * @param {Object} props.item - The audio item data.
 * @param {Function} props.onSelectAudio - The function to handle audio source change.
 * @param {number} props.index - The index of the audio item.
 * @returns {JSX.Element} The rendered audio item.
 */
export const AudioPlaylistAudioItem = ({
  audioSrc,
  item,
  onSelectAudio,
  index
}) => {
  return (
    <ListItemButton
      onClick={() => onSelectAudio(item.audio)}
      selected={audioSrc === item.audio}
    >
      {item.type === 'audio' && item.imageURL && (
        <div>
          <NativeImage
            src={item.imageURL}
            alt={item.title}
            sx={{
              width: '3rem',
              height: '3rem',
              borderRadius: 2,
              objectFit: 'cover',
              marginRight: 2,
              objectPosition: 'center'
            }}
          />
        </div>
      )}
      <ListItemText>
        {index + 1}. {item.title}
      </ListItemText>
    </ListItemButton>
  )
}

/**
 * Renders a list item button for an audio playlist item.
 *
 * @param {Object} item - The audio playlist item object.
 * @param {string} item.linkURL - The URL of the audio link.
 * @param {string} item.title - The title of the audio item.
 * @param {number} index - The index of the audio item in the playlist.
 * @returns {JSX.Element} - The rendered list item button.
 */
export const AudioPlaylistLinkItem = ({ item, index }) => {
  return (
    <ListItemButton
      component='button'
      variant='text'
      LinkComponent='a'
      href={item.linkURL}
      target='_blank'
    >
      <ListItemText
        primaryTypographyProps={{
          noWrap: true
        }}
      >
        {index + 1}. {item.title}
        <InsertLinkRounded
          fontSize='small'
          color='primary'
          sx={{
            borderRadius: '50%',
            bgcolor: 'primary.lighter',
            marginLeft: 1
          }}
        />
      </ListItemText>
      <ListItemIcon>
        <OpenInNew
          sx={{ width: 24, height: 24 }}
          color='disabled'
        />
      </ListItemIcon>
    </ListItemButton>
  )
}

/**
 * Renders a list item button for an audio file in a content page.
 *
 * @param {Object} item - The audio file item.
 * @param {number} index - The index of the audio file item.
 * @param {Object} page - The content page object.
 * @returns {JSX.Element} - The rendered list item button.
 */
export const AudioPlaylistFileItem = ({ item, index, page }) => {
  return (
    <ListItemButton>
      <AccessFileButton
        fullWidth
        fileId={item.file}
        contextType='ContentPage'
        contextId={page._id}
        sx={{
          all: 'initial',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          color: 'inherit',
          width: '100%',
          padding: 0,
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }}
      >
        <ListItemText
          primaryTypographyProps={{
            noWrap: true
          }}
        >
          {index + 1}. {item.title}
          <AttachFileRounded
            fontSize='small'
            color='primary'
            sx={{
              borderRadius: '50%',
              bgcolor: 'primary.lighter',
              marginLeft: 1
            }}
          />
        </ListItemText>
      </AccessFileButton>
      <ListItemIcon>
        <OpenInNew
          sx={{ width: 24, height: 24 }}
          color='disabled'
        />
      </ListItemIcon>
    </ListItemButton>
  )
}
