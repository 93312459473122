// next
import NextLink from 'next/link'
// @mui
import { Box, Card, CardActionArea, Typography } from '@mui/material'
// components
import Logo from './Logo'
// routes
import { PATH_PAGE } from '~/routes/paths'

// i18n
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'

// ----------------------------------------------------------------------

/**
 * @params {Object} props component props
 * @params {Object} props.cardProps props for Card component
 * @params {Object} props.logoProps props for Logo component
 * @returns {JSX.Element}
 */
export default function ClickyCardLogo({ cardProps = {}, logoProps = {} }) {
  // i18n
  const { _ } = useLingui()
  return (
    <Card
      sx={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
      {...cardProps}
    >
      <CardActionArea
        LinkComponent={NextLink}
        href={PATH_PAGE.landing}
      >
        <Box sx={{ padding: 1, textAlign: 'center' }}>
          <Logo
            disabledLink
            nextHeight={20}
            sx={{ width: 'auto', height: '1.5rem', marginX: 'auto' }}
          />
          <Typography
            sx={{ fontSize: '0.6rem', marginTop: '0.3rem' }}
            {...logoProps}
          >
            {_(msg`Promosikan Skillmu`)}
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  )
}
