// @mui
import { Card, CardContent, Typography } from '@mui/material'
// components
import { formatDateTimeCompact } from '~/libs/dateHelper'
// hooks
import { useInvoiceList } from '~/swr/invoice'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {Object} props.page
 * @param {Object} props.product
 * @returns {JSX.Element}
 */
export default function ContentPageProductInfo({ page, product }) {
  const { name } = product
  const { user } = page

  const { data: invoiceList } = useInvoiceList({
    product: product._id,
    status: 'paid',
    user: '$user._id'
  })

  const paidAt = invoiceList?.at(-1)?.paidAt
    ? formatDateTimeCompact(invoiceList?.at(-1)?.paidAt)
    : '...'

  return (
    <Card>
      <CardContent>
        <Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
          {name}
        </Typography>

        {user && user?._id !== product.author && (
          <Typography
            fontSize='small'
            color='text.secondary'
          >
            {`Purchased at ${paidAt}`}
          </Typography>
        )}
      </CardContent>
    </Card>
  )
}
