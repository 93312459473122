// @mui
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
// components
import NativeImage from '~/components/NativeImage'

// subcomponents
import { PromoPageSection } from '../components'

// ----------------------------------------------------------------------

const Image = styled(NativeImage)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  borderRadius: theme.spacing(2)
}))

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {string} props.imageURL
 * @param {string} props.description
 * @returns {JSX.Element}
 */
export default function PromoPageSingleImage({ title, imageURL, description }) {
  return (
    <PromoPageSection title={title}>
      <Image
        src={imageURL}
        alt={title}
      />

      {description && <Typography marginTop={3}>{description}</Typography>}
    </PromoPageSection>
  )
}
