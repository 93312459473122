// react
import { useMemo } from 'react'
// next
import NextLink from 'next/link'
// @mui
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography
} from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
// components
import NativeImage from '~/components/NativeImage'
import { GridCarousel } from '~/components/carousel'

// subcomponents
import { ContentPageSection } from '../components'

import fallbackImage from '~/libs/fallbackImage'

// i18n
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import TextWithSoftBreaks from '~/components/TextWithSoftBreaks'

// ----------------------------------------------------------------------

const CHAPTER_LIST_ORDER = ['text', 'image-single', 'image-swipe', 'image-list']

// ----------------------------------------------------------------------

const ImageLink = styled(NextLink)({
  display: 'block',
  cursor: 'pointer'
})

const Image = styled(NativeImage)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  borderRadius: theme.spacing(2)
}))

/**
 * @param {Object} props
 * @param {Object} props.library
 * @param {Object} props.page
 * @param {Object} props.product
 * @param {string} props.title
 * @param {Object[]} props.items
 * @returns {JSX.Element}
 */
export default function ContentPageStoryChapter({
  title,
  chapters,
  description
}) {
  const { _ } = useLingui()

  const orderedSession = useMemo(() => {
    return chapters.map((chapter) => {
      return {
        ...chapter,
        list: chapter.list.sort((a, b) => {
          return (
            CHAPTER_LIST_ORDER.indexOf(a.type) -
            CHAPTER_LIST_ORDER.indexOf(b.type)
          )
        })
      }
    })
  }, [chapters])

  return (
    <ContentPageSection title={title}>
      <Box>
        {description && (
          <Box sx={{ marginBottom: 1 }}>
            <TextWithSoftBreaks>{description}</TextWithSoftBreaks>
          </Box>
        )}
      </Box>
      <Stack spacing={1.5}>
        {orderedSession.map((chapter, chapterIdx) => (
          <div key={`chapter-${chapterIdx}`}>
            <Accordion
              disableGutters
              variant='outlined'
              sx={{ borderColor: 'grey.300' }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
                aria-controls={`chapter-${chapterIdx}-content`}
                id={`chapter-${chapterIdx}-header`}
              >
                <Typography fontWeight='bold'>
                  {_(msg`Bab ${chapterIdx + 1}:`)} {chapter.title}
                </Typography>

                <Typography>{chapter.description}</Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Stack spacing={2}>
                  {chapter.list.map((item) => (
                    <div key={item.id}>
                      {item.type === 'text' ? (
                        <ChapterItemText item={item} />
                      ) : item.type === 'image-single' ? (
                        <ChapterItemImageSingle item={item} />
                      ) : item.type === 'image-swipe' ? (
                        <ChapterItemImageSwipe
                          item={item}
                          title={title}
                        />
                      ) : item.type === 'image-list' ? (
                        <ChapterItemImageList
                          item={item}
                          title={title}
                        />
                      ) : null}
                    </div>
                  ))}
                </Stack>
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
      </Stack>
    </ContentPageSection>
  )
}

/**
 *
 * @param {Object} item - The story chapter item object.
 * @param {string} item.image - The URL of the image for the story chapter item.
 * @param {string} item.title - The title of the story chapter item.
 * @returns {JSX.Element} - The rendered image component.
 */
export const ChapterItemImageSingle = ({ item }) => {
  return (
    <Box
      component='img'
      src={item.image}
      alt={item.title}
      sx={{
        maxWidth: '100%',
        height: 'auto',
        display: 'block',
        marginTop: 2
      }}
    />
  )
}

/**
 *
 * @param {Object} item - An object containing the image data.
 * @param {Array<{ imageURL: string, linkURL: string }>} item.imageList - An array of objects containing the image URL and optional link URL.
 * @param {string} title - The title of the chapter.
 * @returns {JSX.Element} - A JSX element representing the image carousel.
 */
export const ChapterItemImageSwipe = ({ item, title }) => {
  return (
    <GridCarousel
      isPersistEdgeNavOnMobile
      isEnableDotNav={item.imageList.length > 1}
      isEnableEdgeNav={item.imageList.length > 1}
    >
      {item.imageList.map(({ imageURL, linkURL }, imageNo) =>
        linkURL ? (
          <ImageLink
            key={`image-no-${imageNo}`}
            href={linkURL}
          >
            <ImageLink
              src={imageURL ?? fallbackImage.grey300Pixel}
              alt={`Image ${imageNo} for ${title}`}
            />
          </ImageLink>
        ) : (
          <div key={`image-no-${imageNo}`}>
            <Image
              src={imageURL ?? fallbackImage.grey300Pixel}
              alt={`Image ${imageNo} for ${title}`}
            />
          </div>
        )
      )}
    </GridCarousel>
  )
}

/**
 *
 * @param {Object} item - The story chapter item containing the image list.
 * @param {string[]} item.imageList - The list of image URLs for the story chapter.
 * @param {string} title - The title of the story chapter.
 * @returns {JSX.Element} - A React component that renders the image list.
 */
export const ChapterItemImageList = ({ item, title }) => {
  return (
    <div>
      {item.imageList.map(({ imageURL }) => (
        <div key={`image-${imageURL}`}>
          <NativeImage
            src={imageURL ?? fallbackImage.grey300Pixel}
            alt={`image list for ${title}`}
          />
        </div>
      ))}
    </div>
  )
}

/**
 *
 * @param {Object} props - The component props.
 * @param {Object} props.item - The item to render.
 * @returns {JSX.Element} - The rendered text component.
 */
function ChapterItemText({ item }) {
  return <TextWithSoftBreaks>{item.text}</TextWithSoftBreaks>
}
