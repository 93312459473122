// @mui
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography
} from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'

// subcomponents
import { PromoPageSection } from '../components'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {array} props.items
 * @param {string} props.items[].title
 * @param {string} props.items[].content
 * @returns {JSX.Element}
 */
export default function PromoPageAccordion({ title, items }) {
  return (
    <PromoPageSection title={title}>
      <Stack spacing={1.5}>
        {items.map(({ title: itemTitle, content }) => (
          <div key={`accordion-item-${itemTitle}`}>
            <Accordion
              variant='outlined'
              sx={{ borderColor: 'grey.300' }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
                aria-controls={`accordion-item-${itemTitle}-content`}
                id={`accordion-item-${itemTitle}-header`}
              >
                <Typography fontWeight='medium'>{itemTitle}</Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography>{content}</Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
      </Stack>
    </PromoPageSection>
  )
}
