// @mui
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { VideocamOffOutlined } from '@mui/icons-material'
// config
import { FE_HOSTNAME } from '~/config'
// utils
import { getYouTubeVideoId } from '~/libs/youtube'

// subcomponents
import { PromoPageSection } from '../components'

// ----------------------------------------------------------------------

const PlayerArea = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  paddingTop: '56.25%',
  backgroundColor: theme.palette.grey[300],
  borderRadius: theme.spacing(2),
  overflow: 'hidden'
}))

const YoutubePlayer = styled('iframe')({
  position: 'absolute',
  inset: 0,
  width: '100%',
  height: '100%',
  border: 0
})

const PlayerError = styled('div')({
  position: 'absolute',
  inset: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center'
})

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {string} props.videoURL
 * @param {string} props.description
 * @returns {JSX.Element}
 */
export default function PromoPageYouTubeVideo({
  title,
  videoURL,
  description
}) {
  const videoId = getYouTubeVideoId(videoURL)

  const origin =
    typeof window !== 'undefined' ? window.location.host : FE_HOSTNAME

  return (
    <PromoPageSection title={title}>
      <div>
        <PlayerArea>
          {videoId ? (
            <YoutubePlayer
              id={`video-${videoId}`}
              key={`video-${videoId}`}
              type='text/html'
              src={`https://www.youtube-nocookie.com/embed/${videoId}?enablejsapi=1&origin=${origin}`}
              allowFullScreen
            />
          ) : (
            <PlayerError>
              <VideocamOffOutlined fontSize='large' />
              <Typography variant='shy'>
                There is a problem when trying to play the video. <br />
                (ERROR: INVALID_VIDEO_ID)
              </Typography>
            </PlayerError>
          )}
        </PlayerArea>

        {description && (
          <Typography sx={{ marginTop: 1.5 }}>{description}</Typography>
        )}
      </div>
    </PromoPageSection>
  )
}
