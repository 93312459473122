// next
import NextLink from 'next/link'
import { useRouter } from 'next/router'
// @mui
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { ChevronLeftOutlined, LockOutlined } from '@mui/icons-material'
// components
import CreatorInfoCard from '~/components/CreatorInfoCard'
// hooks
import { useContentPageBySlug } from '~/swr/contentPage'
// paths
import { PATH_APP, PATH_PAGE } from '~/routes/paths'

// i18n
import { useLingui } from '@lingui/react'
import { msg } from '@lingui/macro'

// page specific
import {
  ContentPageAudio,
  ContentPageAccordion,
  ContentPageFile,
  ContentPageGridImage,
  ContentPageImageCarousel,
  ContentPageLinkButton,
  ContentPageListImage,
  ContentPagePlainText,
  ContentPageProductInfo,
  ContentPageSingleImage,
  ContentPageVideo,
  ContentPageWhatsAppButton,
  ContentPageYouTubeVideo,
  ContentPageYouTubePlaylist,
  ContentPageAudioPlaylist,
  ContentPageVideoPlaylist,
  ContentPageClassTraining,
  ContentPageStoryChapter
} from './sections'
import RelatedProduct from '../RelatedProduct'

// ----------------------------------------------------------------------

const ContentBlocker = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  minHeight: 'calc(100vh - 6rem)'
})

// ----------------------------------------------------------------------

const sectionComponent = Object.freeze({
  accordion: ContentPageAccordion,
  audio: ContentPageAudio,
  file: ContentPageFile,
  'grid-image': ContentPageGridImage,
  'image-carousel': ContentPageImageCarousel,
  'link-button': ContentPageLinkButton,
  'list-image': ContentPageListImage,
  'plain-text': ContentPagePlainText,
  'single-image': ContentPageSingleImage,
  video: ContentPageVideo,
  'whatsapp-button': ContentPageWhatsAppButton,
  'youtube-video': ContentPageYouTubeVideo,
  'youtube-playlist': ContentPageYouTubePlaylist,
  'audio-playlist': ContentPageAudioPlaylist,
  'video-playlist': ContentPageVideoPlaylist,
  'class-training': ContentPageClassTraining,
  'story-chapter': ContentPageStoryChapter
})

// ----------------------------------------------------------------------

export { getStaticProps } from '~/i18n/utils'

/**
 * @param {Object} props
 * @param {Object} props.library
 * @param {Object} props.product
 * @param {Object[]} props.relatedProductList
 * @returns {JSX.Element}
 */
export default function ContentPage({ library, product, relatedProductList }) {
  const { isReady, query } = useRouter()
  const slug = query?.args[1]

  const { _ } = useLingui()

  const { data: page, isLoading: isPageLoading } = useContentPageBySlug(
    isReady ? { library: library._id, slug } : null
  )

  const sections = page?.sections || []

  return (
    <Box
      sx={{
        height: 'auto',
        minHeight: '100vh',
        paddingX: 1,
        paddingTop: 2,
        paddingBottom: 3,
        backgroundColor: 'pageBackground'
      }}
    >
      {isPageLoading ? (
        <ContentBlocker>
          <CircularProgress />
        </ContentBlocker>
      ) : page ? (
        <Stack spacing={2}>
          <div>
            <Button
              variant='outlined'
              size='small'
              startIcon={<ChevronLeftOutlined />}
              LinkComponent={NextLink}
              href={PATH_APP.activity.contentAccess}
            >
              {_(msg`Kembali ke akses konten`)}
            </Button>
          </div>

          <CreatorInfoCard
            name={library.author.name || 'Clicky Creator'}
            avatarURL={library.author.photoURL || null}
            libraryBasePath={library.basePath}
          />

          <ContentPageProductInfo
            library={library}
            page={page}
            product={product}
          />

          {sections.map(({ type, props }, sectionNo) => {
            const Section = sectionComponent[type]

            // if section is not recognized, skip it
            if (!Section) {
              console.warn(
                `section #${sectionNo} is not shown because of section type "${type}" is not recognized`
              )

              return null
            }

            return (
              <Section
                key={`section-${sectionNo}`}
                library={library}
                page={page}
                {...props}
              />
            )
          })}

          <Divider />

          <RelatedProduct
            library={library}
            relatedProductList={relatedProductList}
          />
        </Stack>
      ) : (
        <ContentBlocker>
          <div>
            <Box sx={{ textAlign: 'center' }}>
              <LockOutlined sx={{ width: '3rem', height: '3rem' }} />
            </Box>

            <Typography sx={{ textAlign: 'center', color: 'text.secondary' }}>
              {_(msg`Oops, Kamu tidak memiliki akses ke halaman ini.`)}
            </Typography>

            <Grid
              container
              spacing={1.5}
              sx={{ justifyContent: 'center', marginTop: 1 }}
            >
              <Grid
                item
                xs='auto'
              >
                <Button
                  LinkComponent={NextLink}
                  href={PATH_PAGE.redirect.promoPage(product._id)}
                  variant='contained'
                  color='warning'
                >
                  {_(msg`Dapatkan Akses`)}
                </Button>
              </Grid>

              <Grid
                item
                xs='auto'
              >
                <Button
                  LinkComponent={NextLink}
                  href={`/${library.basePath}`}
                  variant='contained'
                >
                  {_(msg`Kunjungi Creator`)}
                </Button>
              </Grid>

              <Grid
                item
                xs='auto'
              >
                <Button
                  LinkComponent={NextLink}
                  href={PATH_PAGE.landing}
                  variant='contained'
                >
                  {_(msg`Kembali ke halaman utama`)}
                </Button>
              </Grid>
            </Grid>
          </div>
        </ContentBlocker>
      )}
    </Box>
  )
}
