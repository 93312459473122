// @mui
import { Box, Typography } from '@mui/material'
// components
import TextWithSoftBreaks from '~/components/TextWithSoftBreaks'

// component specific
import { PromoPageSection } from '../components'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {Object} props.library
 * @param {Object} props.page
 * @param {string} props.title
 * @param {'left'|'right'|'center'|'justify'} props.alignment
 * @param {string} props.subtitle
 * @param {string} props.content
 * @returns {JSX.Element}
 */
export default function PromoPagePlainText({
  title,
  alignment,
  subtitle,
  content
}) {
  return (
    <PromoPageSection
      title={title}
      alignTitle={alignment}
    >
      <Box sx={{ textAlign: alignment }}>
        {subtitle && (
          <Typography sx={{ marginBottom: 1, color: 'text.secondary' }}>
            {subtitle}
          </Typography>
        )}

        <TextWithSoftBreaks>{content}</TextWithSoftBreaks>
      </Box>
    </PromoPageSection>
  )
}
