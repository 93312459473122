// @mui
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Box,
  Stack,
  Typography
} from '@mui/material'
import {
  AttachFileRounded,
  ExpandMoreOutlined,
  InsertLinkRounded,
  VideocamOffOutlined
} from '@mui/icons-material'
import { styled } from '@mui/material/styles'
// components
import AccessFileButton from '~/components/AccessFileButton'
import TextWithSoftBreaks from '~/components/TextWithSoftBreaks'
import NativeImage from '~/components/NativeImage'
// i18n
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
// utils
import { getYouTubeVideoId } from '~/libs/youtube'

// page specific
import { ContentPageSection } from '../components'

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const PlayerArea = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  paddingTop: '56.25%',
  backgroundColor: theme.palette.grey[300],
  borderRadius: theme.spacing(2),
  overflow: 'hidden'
}))

const YoutubePlayer = styled('iframe')({
  position: 'absolute',
  inset: 0,
  width: '100%',
  height: '100%',
  border: 0
})

const PlayerError = styled('div')({
  position: 'absolute',
  inset: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center'
})

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {Object} props.library
 * @param {Object} props.page
 * @param {Object} props.product
 * @param {string} props.title
 * @param {Object[]} props.items
 * @returns {JSX.Element}
 */
export default function ContentPageClassTraining({
  title,
  sessions,
  page,
  description
}) {
  const { _ } = useLingui()

  return (
    <ContentPageSection title={title}>
      <Box>
        {description && (
          <Box sx={{ marginBottom: 1 }}>
            <TextWithSoftBreaks>{description}</TextWithSoftBreaks>
          </Box>
        )}
      </Box>
      <Stack spacing={1.5}>
        {sessions.map((session, sessionIdx) => (
          <div key={`session-${sessionIdx}`}>
            <Accordion
              disableGutters
              variant='outlined'
              sx={{ borderColor: 'grey.300' }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
                aria-controls={`session-${sessionIdx}-content`}
                id={`session-${sessionIdx}-header`}
              >
                <Typography fontWeight='bold'>
                  {_(msg`Sesi ${sessionIdx + 1}:`)} {session.title}
                </Typography>

                <Typography>{session.description}</Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Stack spacing={1.5}>
                  {session.list.map((item) => (
                    <div key={item.id}>
                      {item.type === 'youtube' ? (
                        <SessionItemYoutube item={item} />
                      ) : item.type === 'file' ? (
                        <SessionItemFile
                          item={item}
                          contentPage={page}
                        />
                      ) : item.type === 'link' ? (
                        <SessionItemLink item={item} />
                      ) : item.type === 'content' ? (
                        <SessionItemContent item={item} />
                      ) : item.type === 'image' ? (
                        <SessionItemImage item={item} />
                      ) : null}
                    </div>
                  ))}
                </Stack>
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
      </Stack>
    </ContentPageSection>
  )
}

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {Object} props.item
 * @returns {JSX.Element}
 */
function SessionItemYoutube({ item }) {
  const videoId = getYouTubeVideoId(item.youtubeURL)

  return (
    <Stack spacing={1}>
      {item.title && <Typography fontWeight='medium'>{item.title}</Typography>}
      <PlayerArea>
        {videoId ? (
          <YoutubePlayer
            id={`video-${videoId}`}
            key={`video-${videoId}`}
            type='text/html'
            src={`https://www.youtube-nocookie.com/embed/${videoId}?enablejsapi=1&origin=${origin}`}
            allowFullScreen
          />
        ) : (
          <PlayerError>
            <VideocamOffOutlined fontSize='large' />
            <Typography variant='shy'>
              There is a problem when trying to play the video. <br />
              (ERROR: INVALID_VIDEO_ID)
            </Typography>
          </PlayerError>
        )}
      </PlayerArea>
    </Stack>
  )
}

/**
 * @param {Object} props
 * @param {Object} props.item
 * @param {Object} props.contentPage
 * @returns {JSX.Element}
 */
function SessionItemFile({ item, contentPage }) {
  return (
    <AccessFileButton
      size='small'
      variant='contained'
      color='warning'
      fileId={item.file}
      contextType='ContentPage'
      contextId={contentPage._id}
      rel='noopener noreferrer'
      startIcon={<AttachFileRounded />}
    >
      <Typography fontWeight='medium'>{item.title}</Typography>
    </AccessFileButton>
  )
}

/**
 * @param {Object} props
 * @param {Object} props.item
 * @returns {JSX.Element}
 */
function SessionItemLink({ item }) {
  return (
    <Button
      href={item.linkURL}
      target='_blank'
      size='small'
      variant='contained'
      color='warning'
      rel='noopener noreferrer'
      startIcon={<InsertLinkRounded />}
    >
      <Typography fontWeight='medium'>{item.title}</Typography>
    </Button>
  )
}

/**
 * @param {Object} props
 * @param {Object} props.item
 * @returns {JSX.Element}
 */
function SessionItemContent({ item }) {
  return <TextWithSoftBreaks>{item.value}</TextWithSoftBreaks>
}

/**
 * @param {Object} props
 * @param {Object} props.item
 * @returns {JSX.Element}
 */
function SessionItemImage({ item }) {
  return (
    <NativeImage
      src={item.imageURL}
      alt={item.title}
      sx={{
        maxWidth: '100%',
        height: 'auto',
        display: 'block',
        marginTop: 2
      }}
    />
  )
}
