import { useState } from 'react'
// @mui
import {
  Box,
  Typography,
  ListItemIcon,
  ListItemButton,
  ListItem,
  ListItemText
} from '@mui/material'
import TextWithSoftBreaks from '~/components/TextWithSoftBreaks'

import { styled } from '@mui/material/styles'
import {
  VideocamOffOutlined,
  // PlayCircleOutlined,
  OpenInNew,
  AttachFileRounded,
  InsertLinkRounded
} from '@mui/icons-material'

// config
import { FE_HOSTNAME } from '~/config'
// utils
import { getYouTubeVideoId } from '~/libs/youtube'

// subcomponents
import { ContentPageSection } from '../components'
import AccessFileButton from '~/components/AccessFileButton'

// ----------------------------------------------------------------------

const PlayerArea = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  paddingTop: '56.25%',
  backgroundColor: theme.palette.grey[300],
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  marginBottom: theme.spacing(2)
}))

const YoutubePlayer = styled('iframe')({
  position: 'absolute',
  inset: 0,
  width: '100%',
  height: '100%',
  border: 0
})

const PlayerError = styled('div')({
  position: 'absolute',
  inset: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center'
})

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {string} props.videoURL
 * @param {string} props.description
 * @returns {JSX.Element}
 */
export default function ContentPageYouTubePlaylist({
  title,
  description,
  list,
  page
}) {
  //   const videoId = getYouTubeVideoId(youtubeURL)

  const origin =
    typeof window !== 'undefined' ? window.location.host : FE_HOSTNAME

  const firstYoutubeItem = list.find(
    (item) => item.type === 'youtube' && item.youtubeURL
  )

  // Get the video ID of the first YouTube item
  const firstVideoId = firstYoutubeItem
    ? getYouTubeVideoId(firstYoutubeItem.youtubeURL)
    : null

  // Set the initial value of youtubePlayerSrc state to the embed URL of the first YouTube item
  const [youtubePlayerSrc, setYoutubePlayerSrc] = useState(
    firstVideoId
      ? `https://www.youtube-nocookie.com/embed/${firstVideoId}?enablejsapi=1&origin=${origin}`
      : null
  )
  const [currentVideo, setCurrentVideo] = useState(firstVideoId)

  const handleYoutubeSrcChange = (youtubeURL) => {
    const videoId = getYouTubeVideoId(youtubeURL)
    const newSrc = `https://www.youtube-nocookie.com/embed/${videoId}?enablejsapi=1&origin=${origin}&autoplay=1`
    setYoutubePlayerSrc(newSrc)
    setCurrentVideo(videoId)
  }

  return (
    <ContentPageSection title={title}>
      <Box>
        {description && (
          <Box sx={{ marginBottom: 1 }}>
            <TextWithSoftBreaks>{description}</TextWithSoftBreaks>
          </Box>
        )}
      </Box>
      <PlayerArea>
        {youtubePlayerSrc ? (
          <YoutubePlayer
            id={`video-${currentVideo}`}
            key={`video-${currentVideo}`}
            type='text/html'
            src={youtubePlayerSrc}
            allowFullScreen
          />
        ) : (
          <PlayerError>
            <VideocamOffOutlined fontSize='large' />
            <Typography variant='shy'>
              There is a problem when trying to play the video. <br />
              (ERROR: INVALID_VIDEO_ID)
            </Typography>
          </PlayerError>
        )}
      </PlayerArea>

      <Box style={{ maxHeight: '14.6rem', overflowY: 'auto' }}>
        {list.map((item, index) => {
          return (
            <div key={index}>
              <Box>
                <ListItem
                  divider
                  disablePadding
                >
                  {item.type === 'youtube' ? (
                    <YoutubePlaylistYoutubeItem
                      item={item}
                      index={index}
                      onSelectYoutubeVideo={handleYoutubeSrcChange}
                    />
                  ) : item.type === 'link' ? (
                    <YoutubePlaylistLinkItem
                      item={item}
                      index={index}
                    />
                  ) : item.type === 'file' ? (
                    <YoutubePlaylistFileItem
                      item={item}
                      index={index}
                      page={page}
                    />
                  ) : null}
                </ListItem>
              </Box>
            </div>
          )
        })}
      </Box>
    </ContentPageSection>
  )
}

/**
 * Renders a list item button for a YouTube playlist item.
 *
 * @param {Object} item - The YouTube playlist item object.
 * @param {number} index - The index of the playlist item.
 * @param {function} onSelectYoutubeVideo - A function to handle changes to the YouTube source.
 * @param {string} currentVideo - The ID of the currently selected YouTube video.
 * @returns {JSX.Element} - The rendered list item button.
 */
export const YoutubePlaylistYoutubeItem = ({
  item,
  index,
  onSelectYoutubeVideo,
  currentVideo
}) => {
  const isCurrentVideo =
    item.type === 'youtube' &&
    getYouTubeVideoId(item.youtubeURL) === currentVideo
  return (
    <ListItemButton
      onClick={() => onSelectYoutubeVideo(item.youtubeURL)}
      selected={getYouTubeVideoId(item.youtubeURL) === isCurrentVideo}
    >
      <ListItemText>
        {index + 1}. {item.title}
      </ListItemText>
    </ListItemButton>
  )
}

/**
 * Renders a list item button that links to a YouTube playlist item.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.item - The playlist item data.
 * @param {string} props.item.linkURL - The URL of the playlist item.
 * @param {string} props.item.title - The title of the playlist item.
 * @param {number} props.index - The index of the playlist item in the list.
 * @returns {JSX.Element} - The rendered list item button.
 */
export const YoutubePlaylistLinkItem = ({ item, index }) => {
  return (
    <ListItemButton
      component='button'
      variant='text'
      LinkComponent='a'
      href={item.linkURL}
      target='_blank'
    >
      <ListItemText
        primaryTypographyProps={{
          noWrap: true
        }}
      >
        {index + 1}. {item.title}
        <InsertLinkRounded
          fontSize='small'
          color='primary'
          sx={{
            borderRadius: '50%',
            bgcolor: 'primary.lighter',
            marginLeft: 1
          }}
        />
      </ListItemText>
      <ListItemIcon>
        <OpenInNew
          color='disabled'
          sx={{ width: 24, height: 24 }}
        />
      </ListItemIcon>
    </ListItemButton>
  )
}

/**
 * Renders a list item button for a YouTube playlist file item.
 *
 * @param {Object} item - The YouTube playlist file item.
 * @param {number} index - The index of the file item in the playlist.
 * @param {Object} page - The content page object.
 * @returns {JSX.Element} - The rendered list item button.
 */
export const YoutubePlaylistFileItem = ({ item, index, page }) => {
  return (
    <ListItemButton>
      <AccessFileButton
        fullWidth
        fileId={item.file}
        contextType='ContentPage'
        contextId={page._id}
        sx={{
          all: 'initial',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: '100%',
          color: 'inherit',

          padding: 0,
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }}
      >
        <ListItemText
          primaryTypographyProps={{
            noWrap: true
          }}
        >
          {index + 1}. {item.title}
          <AttachFileRounded
            fontSize='small'
            color='primary'
            sx={{
              borderRadius: '50%',
              bgcolor: 'primary.lighter',
              marginLeft: 1
            }}
          />
        </ListItemText>
      </AccessFileButton>
      <ListItemIcon>
        <OpenInNew
          sx={{ width: 24, height: 24 }}
          color='disabled'
        />
      </ListItemIcon>
    </ListItemButton>
  )
}
