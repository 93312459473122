import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'

// @mui
import {
  Box,
  ListItemIcon,
  ListItemButton,
  ListItem,
  ListItemText
} from '@mui/material'
import {
  // PlayCircleOutlined,
  OpenInNew,
  AttachFileRounded,
  InsertLinkRounded
} from '@mui/icons-material'
// components
import TextWithSoftBreaks from '~/components/TextWithSoftBreaks'
import VideoPlayer from '~/components/VideoPlayer'
import AccessFileButton from '~/components/AccessFileButton'

// component specific
import { ContentPageSection } from '../components'

// ----------------------------------------------------------------------
/**
 * @param {Object} props
 * @param {string} props.title
 * @param {string} props.description
 * @param {Object} props.page
 * @returns {JSX.Element}
 */
export default function ContentPageVideoPlaylist({
  title,
  description,
  page,
  list
}) {
  const router = useRouter()

  const firstVideoItem = list.find(
    (item) => item.type === 'video' && item.video
  )

  const [videoSrc, setVideoSrc] = useState(
    firstVideoItem ? firstVideoItem.video : null
  )
  const [autoPlay, setAutoPlay] = useState(false)

  const handleVideoSrcChange = (video) => {
    setVideoSrc(video)
    setAutoPlay(true)
  }

  useEffect(() => {
    if (router.isReady) {
      setAutoPlay(false)
    }
  }, [router.isReady])

  return (
    <ContentPageSection title={title}>
      <Box>
        {description && (
          <Box sx={{ marginBottom: 1 }}>
            <TextWithSoftBreaks>{description}</TextWithSoftBreaks>
          </Box>
        )}
      </Box>

      {videoSrc && (
        <Box marginBottom={2}>
          <VideoPlayer
            fileId={videoSrc}
            accessContext={{
              contextType: 'ContentPage',
              contextId: page._id
            }}
            autoPlay={autoPlay ? 'any' : false}
          />
        </Box>
      )}

      <Box
        style={{
          maxHeight: '14.6rem',
          overflowY: 'auto'
        }}
      >
        {list.map((item, index) => {
          return (
            <div key={index}>
              <ListItem
                divider
                disablePadding
              >
                {item.type === 'video' ? (
                  <VideoPlaylistVideoItem
                    item={item}
                    index={index}
                    videoSrc={videoSrc}
                    onSelectVideo={handleVideoSrcChange}
                  />
                ) : item.type === 'link' ? (
                  <VideoPlaylistLinkItem
                    item={item}
                    index={index}
                  />
                ) : item.type === 'file' ? (
                  <VideoPlaylistFileItem
                    item={item}
                    index={index}
                    page={page}
                  />
                ) : null}
              </ListItem>
            </div>
          )
        })}
      </Box>
    </ContentPageSection>
  )
}

// ----------------------------------------------------------------------

/**
 * Renders a video playlist item that displays the video title and allows the user to select the video.
 *
 * @param {Object} item - The video item object.
 * @param {string} item.video - The URL of the video.
 * @param {string} item.title - The title of the video.
 * @param {number} index - The index of the video in the playlist.
 * @param {string} videoSrc - The currently selected video source.
 * @param {function} onSelectVideo - A callback function to be called when the user selects the video.
 * @returns {JSX.Element} - The rendered video playlist item.
 */
export const VideoPlaylistVideoItem = ({
  item,
  index,
  videoSrc,
  onSelectVideo
}) => {
  return (
    <ListItemButton
      onClick={() => onSelectVideo(item.video)}
      selected={videoSrc === item.video}
    >
      <ListItemText>
        {index + 1}. {item.title}
      </ListItemText>
    </ListItemButton>
  )
}

/**
 * Renders a list item button for a video playlist item.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.item - The video playlist item object.
 * @param {number} props.index - The index of the video playlist item.
 * @returns {JSX.Element} The rendered list item button.
 */
export const VideoPlaylistLinkItem = ({ item, index }) => {
  return (
    <ListItemButton
      component='button'
      variant='text'
      LinkComponent='a'
      href={item.linkURL}
      target='_blank'
    >
      <ListItemText
        primaryTypographyProps={{
          noWrap: true
        }}
      >
        {index + 1}. {item.title}
        <InsertLinkRounded
          fontSize='small'
          color='primary'
          sx={{
            borderRadius: '50%',
            bgcolor: 'primary.lighter',
            marginLeft: 1
          }}
        />
      </ListItemText>
      <ListItemIcon>
        <OpenInNew
          sx={{ width: 24, height: 24 }}
          color='disabled'
        />
      </ListItemIcon>
    </ListItemButton>
  )
}

/**
 * Renders a list item button for a video playlist file item.
 *
 * @param {Object} item - The video playlist file item.
 * @param {number} index - The index of the video playlist file item.
 * @param {Object} page - The current content page.
 * @returns {JSX.Element} The rendered list item button.
 */
export const VideoPlaylistFileItem = ({ item, index, page }) => {
  return (
    <ListItemButton>
      <AccessFileButton
        fullWidth
        fileId={item.file}
        contextType='ContentPage'
        contextId={page._id}
        sx={{
          all: 'initial',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          color: 'inherit',
          width: '100%',
          padding: 0,
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }}
      >
        <ListItemText
          primaryTypographyProps={{
            noWrap: true
          }}
        >
          {index + 1}. {item.title}
          <AttachFileRounded
            fontSize='small'
            color='primary'
            sx={{
              borderRadius: '50%',
              bgcolor: 'primary.lighter',
              marginLeft: 1
            }}
          />
        </ListItemText>
      </AccessFileButton>
      <ListItemIcon>
        <OpenInNew
          sx={{ width: 24, height: 24 }}
          color='disabled'
        />
      </ListItemIcon>
    </ListItemButton>
  )
}
