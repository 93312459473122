// @mui
import { Box } from '@mui/material'
// components
import ClickyCardLogo from '~/components/ClickyCardLogo'
// hooks
import useOffSetTop from '~/hooks/useOffSetTop'

// ----------------------------------------------------------------------

/**
 * @returns {JSX.Element}
 */
export default function ClickyFloatingLogo() {
  const offsetTop = useOffSetTop(64, 'pixel')

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 10,
        left: '50%',
        bottom: '0.5rem',
        transition: '0.3s ease-out',

        // animation
        ...(offsetTop
          ? { transform: 'translate(-50%, 0)', opacity: 1 }
          : { transform: 'translate(-50%, 100%)', opacity: 0 })
      }}
    >
      <ClickyCardLogo />
    </Box>
  )
}
