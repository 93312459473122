// import
import NextLink from 'next/link'
// @mui
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Stack,
  Typography
} from '@mui/material'
// config
import { FE_HOSTNAME, IMAGE_CDN_URL } from '~/config'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} props.name
 * @param {string} props.avatarURL
 * @param {string} props.libraryBasePath
 * @returns {JSX.Element}
 */
export default function CreatorInfoCard({ name, avatarURL, libraryBasePath }) {
  return (
    <Card>
      <CardActionArea
        LinkComponent={NextLink}
        href={`/${libraryBasePath}`}
      >
        <CardContent sx={{ padding: 1 }}>
          <Stack
            direction='row'
            spacing={1.5}
            sx={{ width: '100%', alignItems: 'center' }}
          >
            <Avatar
              sx={{ width: '2.25rem', height: '2.25rem' }}
              src={
                avatarURL
                  ? !avatarURL.startsWith('https://') &&
                    !avatarURL.startsWith('http://')
                    ? `${IMAGE_CDN_URL}/${avatarURL}`
                    : avatarURL
                  : null
              }
            />

            <Box sx={{ flexGrow: 1, height: '100%' }}>
              <Typography sx={{ fontWeight: 'bold', fontSize: 'small' }}>
                {name}
              </Typography>

              <Typography sx={{ fontSize: 'small', color: 'text.secondary' }}>
                {FE_HOSTNAME}/{libraryBasePath}
              </Typography>
            </Box>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
