// @mui
import { Box } from '@mui/material'
// components
import TextWithSoftBreaks from '~/components/TextWithSoftBreaks'
import AccessFileButton from '~/components/AccessFileButton'

// component specific
import { ContentPageSection } from '../components'

// ----------------------------------------------------------------------
/**
 * @param {Object} props
 * @param {string} props.title
 * @param {string} props.description
 * @param {string} props.file
 * @param {Object} props.buttonText
 * @param {Object} props.page
 * @returns {JSX.Element}
 */
export default function ContentPageFile({ title, description, file, page }) {
  return (
    <ContentPageSection title={title}>
      <Box>
        {description && (
          <Box sx={{ marginBottom: 1 }}>
            <TextWithSoftBreaks>{description}</TextWithSoftBreaks>
          </Box>
        )}

        <AccessFileButton
          size='large'
          fullWidth
          variant='contained'
          fileId={file}
          contextType='ContentPage'
          contextId={page._id}
        >
          Download File
        </AccessFileButton>
      </Box>
    </ContentPageSection>
  )
}
