// @mui
import { Box, Card, CardContent, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

// ----------------------------------------------------------------------

const SectionContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(0)
}))

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {React.ReactNode} props.children
 * @param {string} [props.alignTitle='left']
 */
export default function PromoPageSection({
  title,
  children,
  alignTitle = 'left'
}) {
  return (
    <SectionContainer>
      <Card>
        <CardContent>
          {title && (
            <Box sx={{ marginBottom: 1.5, textAlign: alignTitle }}>
              <Typography variant='hx'>{title}</Typography>
            </Box>
          )}

          <div>{children}</div>
        </CardContent>
      </Card>
    </SectionContainer>
  )
}
