// @mui
import { Box, Card, Typography } from '@mui/material'
// components
import { GridCarousel } from '~/components/carousel'

// subcomponents
import { PromoPageSection } from '../components'
import TextMaxLine from '~/components/TextMaxLine'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {string} props.description
 * @param {Object[]} props.testimonies
 * @param {string} props.testimonies[].title
 * @param {string} props.testimonies[].name
 * @param {string} props.testimonies[].content
 * @returns {JSX.Element}
 */
export default function PromoPageTestimony({
  title,
  description,
  testimonies
}) {
  return (
    <PromoPageSection title={title}>
      {description && <Typography marginBottom={1}>{description}</Typography>}

      <GridCarousel
        columns={1}
        peek={0.25}
        isEnableDotNav={testimonies.length > 1}
        isEnableEdgeNav={testimonies.length > 1}
      >
        {testimonies.map(({ title: testimonyTitle, name, content }) => (
          <Card
            key={`testimony-from-${name}`}
            variant='outlined'
          >
            <Box
              padding={2}
              textAlign='center'
            >
              <TextMaxLine
                line={4}
                sx={{
                  minHeight: '6em',
                  fontSize: { xs: '0.85rem', md: '1rem' },
                  marginBottom: 1.5
                }}
              >
                {content}
              </TextMaxLine>

              <TextMaxLine
                line={1}
                sx={{
                  fontWeight: 'bold',
                  color: 'primary.main'
                }}
              >
                {name}
              </TextMaxLine>

              <TextMaxLine
                line={1}
                sx={{ color: 'text.secondary', fontSize: 'small' }}
              >
                {testimonyTitle}
              </TextMaxLine>
            </Box>
          </Card>
        ))}
      </GridCarousel>
    </PromoPageSection>
  )
}
