// next
import NextLink from 'next/link'
// @mui
import { Box, Button, Container, Divider, Grid, Stack } from '@mui/material'
import { LoadingButton } from '@mui/lab'
// hooks
import { useInvoiceList } from '~/swr/invoice'
// components
import CreatorInfoCard from '~/components/CreatorInfoCard'
import RelatedProduct from '~/components/RelatedProduct'
import ClickyCardLogo from '~/components/ClickyCardLogo'
// paths
import { PATH_PAGE } from '~/routes/paths'
// utils
import productHelper from '~/libs/productHelper'

// i18n
import { useLingui } from '@lingui/react'
import { msg } from '@lingui/macro'

// page specific
import {
  PromoPageAccordion,
  PromoPageGridImage,
  PromoPageImageCarousel,
  PromoPagePlainText,
  PromoPageProductInfo,
  PromoPageSingleImage,
  PromoPageTestimony,
  PromoPageWhatsAppButton,
  PromoPageYouTubeVideo
} from './sections'

// ----------------------------------------------------------------------

const SECTION_COMPONENT = Object.freeze({
  accordion: PromoPageAccordion,
  'grid-image': PromoPageGridImage,
  'image-carousel': PromoPageImageCarousel,
  'plain-text': PromoPagePlainText,
  'single-image': PromoPageSingleImage,
  testimony: PromoPageTestimony,
  'whatsapp-button': PromoPageWhatsAppButton,
  'youtube-video': PromoPageYouTubeVideo
})

const REPURCHASE_BUTTON_LABEL = {
  donation: 'Donate Again',
  fixed: 'Repurchase Product',
  minimum: 'Repurchase Product',
  tipping: 'Tip Again'
}

// ----------------------------------------------------------------------

/**
 * Promotional page main component
 * @param {Object} props component props
 * @param {Object} props.library library data for this page
 * @param {Object} props.product product data for this page
 * @param {Object} props.page page data
 * @param {Object[]} props.relatedProducts page sections data
 * @returns {JSX.Element}
 */
export default function PromoPage({
  library,
  product,
  page,
  relatedProductList
}) {
  const { sections } = page

  // i18n
  const { _ } = useLingui()

  // product availability
  const isProductAvailable = productHelper.isProductAvailable(product)

  // handle user already purchase this product
  const { data: invoiceList, isLoading: isInvoiceListLoading } = useInvoiceList(
    {
      product: product._id,
      user: '$user._id',
      status: 'paid'
    }
  )

  const isUserPurchasedProduct = invoiceList?.length > 0

  return (
    <Box
      sx={{
        height: 'auto',
        minHeight: '100vh',
        paddingX: 1,
        paddingTop: 2,
        paddingBottom: 3,
        backgroundColor: 'pageBackground'
      }}
    >
      <Stack spacing={2}>
        <CreatorInfoCard
          name={library.author.name || 'Clicky Creator'}
          avatarURL={library.author.photoURL || null}
          libraryBasePath={library.basePath}
        />

        <PromoPageProductInfo
          library={library}
          page={page}
          product={product}
          isProductAvailable={isProductAvailable}
        />

        {sections.map(({ type, props }, sectionNo) => {
          const Section = SECTION_COMPONENT[type]

          // if section is not recognized, skip it
          if (!Section) {
            console.warn(
              `section #${sectionNo} is not shown because of section type "${type}" is not recognized`
            )

            return null
          }

          return (
            <Section
              key={`section-${sectionNo}`}
              library={library}
              page={page}
              {...props}
            />
          )
        })}

        <Divider />

        {!isInvoiceListLoading && isUserPurchasedProduct && (
          <>
            <div>
              <Button
                LinkComponent={NextLink}
                fullWidth
                variant='outlined'
                color='primary'
                size='large'
                href={PATH_PAGE.redirect.contentPage(product._id)}
              >
                {_(msg`Akses Produk`)}
              </Button>
            </div>

            <Divider />
          </>
        )}

        <RelatedProduct
          library={library}
          relatedProductList={relatedProductList}
        />

        <Divider />

        <div>
          <Grid
            container
            justifyContent='center'
          >
            <Grid
              item
              xs='auto'
            >
              <ClickyCardLogo />
            </Grid>
          </Grid>
        </div>
      </Stack>

      {
        // keep showing purchase button even if user already purchased this product
        // except if the product is free
        !(isUserPurchasedProduct && product.priceType === 'free') &&
          // only show purchase button if product is available
          isProductAvailable &&
          !product.isSoldOut && (
            <Box
              sx={{
                position: 'fixed',
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 20,
                width: '100%'
              }}
            >
              <Container
                maxWidth='sm'
                disableGutters
                sx={{ p: 1, backgroundColor: 'rgba(255, 255, 255, 0.85)' }}
              >
                <LoadingButton
                  LinkComponent={NextLink}
                  href={PATH_PAGE.payment.purchase(product._id)}
                  fullWidth
                  variant='contained'
                  color='primary'
                  size='large'
                >
                  {isUserPurchasedProduct
                    ? REPURCHASE_BUTTON_LABEL[product.priceType]
                    : _(msg`Lanjutkan`)}
                </LoadingButton>
              </Container>
            </Box>
          )
      }
    </Box>
  )
}
