import { createFetchingSWR } from './_factory'

import { invoiceService } from '~/services'

export const useInvoiceList = createFetchingSWR({
  key: 'invoices',
  fetcher: invoiceService.getList,
  withAuth: 'always'
})

export const useInvoiceStats = createFetchingSWR({
  key: 'invoices-stats',
  fetcher: invoiceService.getStats,
  withAuth: 'always'
})

export const useInvoiceAggregateAmount = createFetchingSWR({
  key: 'invoice-aggregate-amount',
  fetcher: invoiceService.getAggregateAmount,
  withAuth: 'always'
})

export const useInvoiceAggregateDate = createFetchingSWR({
  key: 'invoice-aggregate-date',
  fetcher: invoiceService.getAggregateDate,
  withAuth: 'always'
})

export const usePaymentMethodeList = createFetchingSWR({
  key: 'payment-method-list',
  fetcher: invoiceService.getPaymentMethods,
  withAuth: 'always'
})

export const useInvoice = createFetchingSWR({
  key: 'invoice-by-id',
  fetcher: invoiceService.getById,
  withAuth: 'always'
})

// multi buyer invoice
export const useMultiBuyerInvoiceList = createFetchingSWR({
  key: 'multibuyer-invoice-list',
  fetcher: invoiceService.getMultiBuyerInvoiceList,
  withAuth: 'always'
})

export const useMultibuyerInvoice = createFetchingSWR({
  key: 'multibuyer-invoice-by-id',
  fetcher: invoiceService.getMultiBuyerInvoice,
  withAuth: 'always'
})
