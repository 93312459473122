// @mui
import { Box } from '@mui/material'
// components
import TextWithSoftBreaks from '~/components/TextWithSoftBreaks'
import AudioPlayer from '~/components/AudioPlayer'

// component specific
import { ContentPageSection } from '../components'

// ----------------------------------------------------------------------
/**
 * @param {Object} props
 * @param {string} props.title
 * @param {string} props.description
 * @param {string} props.audio
 * @param {Object} props.page
 * @returns {JSX.Element}
 */
export default function ContentPageAudio({ title, description, audio, page }) {
  return (
    <ContentPageSection title={title}>
      <Box>
        {description && (
          <Box sx={{ marginBottom: 1 }}>
            <TextWithSoftBreaks>{description}</TextWithSoftBreaks>
          </Box>
        )}

        <AudioPlayer
          fileId={audio}
          accessContext={{
            contextType: 'ContentPage',
            contextId: page._id
          }}
        />
      </Box>
    </ContentPageSection>
  )
}
