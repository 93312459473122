// @mui
import { Button } from '@mui/material'
import { WhatsApp } from '@mui/icons-material'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} props.whatsAppURL
 * @param {string} props.buttonLabel
 * @returns {JSX.Element}
 */
export default function PromoPageWhatsAppButton({ whatsAppURL, buttonLabel }) {
  return (
    <Button
      startIcon={<WhatsApp fontSize='large' />}
      variant='contained'
      color='warning'
      size='large'
      LinkComponent='a'
      href={`https://api.whatsapp.com/send?phone=${whatsAppURL}`}
      target='_blank'
      sx={{ textAlign: 'center' }}
    >
      {buttonLabel}
    </Button>
  )
}
