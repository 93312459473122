// @mui
import { Box } from '@mui/material'
// components
import TextWithSoftBreaks from '~/components/TextWithSoftBreaks'
import VideoPlayer from '~/components/VideoPlayer'

// component specific
import { ContentPageSection } from '../components'

// ----------------------------------------------------------------------
/**
 * @param {Object} props
 * @param {string} props.title
 * @param {string} props.description
 * @param {string} props.video
 * @param {Object} props.page
 * @returns {JSX.Element}
 */
export default function ContentPageVideo({ title, description, video, page }) {
  return (
    <ContentPageSection title={title}>
      <Box>
        {description && (
          <Box sx={{ marginBottom: 1 }}>
            <TextWithSoftBreaks>{description}</TextWithSoftBreaks>
          </Box>
        )}

        <div>
          <VideoPlayer
            fileId={video}
            accessContext={{
              contextType: 'ContentPage',
              contextId: page._id
            }}
          />
        </div>
      </Box>
    </ContentPageSection>
  )
}
