// next
import NextLink from 'next/link'
// @mui
import { Card, CardContent, Chip, Grid, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
// components
import NativeImage from '~/components/NativeImage'
import { GridCarousel } from '~/components/carousel'
import TextWithSoftBreaks from '~/components/TextWithSoftBreaks'
// utils
import { DateTime } from 'luxon'
import { formatIDR } from '~/libs/numberFormatter'
import fallbackImage from '~/libs/fallbackImage'

// i18n
import { useLingui } from '@lingui/react'
import { msg } from '@lingui/macro'

// ----------------------------------------------------------------------

const ImageLink = styled(NextLink)({
  display: 'block',
  cursor: 'pointer'
})

const Image = styled(NativeImage)({
  width: '100%',
  height: 'auto'
})

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {Object} props.library passed from parent but not used
 * @param {Object} props.page passed from parent but not used
 * @param {Object} props.product
 * @param {boolean} props.isProductAvailable
 */
export default function PromoPageProductInfo({ product, isProductAvailable }) {
  const {
    name,
    subtitle,
    description,
    highlightImages,
    price,
    priceBeforeDiscount,
    startSaleAt,
    endSaleAt,
    isShowTransactionCount,
    maxRegistrant,
    // available, but not used for now, use data from showcase instead
    // transactionCount,
    showcase
  } = product

  const { _ } = useLingui()

  // render helper
  let saleCountdown = null
  const isAfterSaleAt =
    endSaleAt && DateTime.fromISO(endSaleAt) < DateTime.now()
  const isBeforeSaleAt =
    startSaleAt && DateTime.fromISO(startSaleAt) > DateTime.now()

  if (!isProductAvailable) {
    // product not available, default message
    saleCountdown = {
      color: 'error',
      label: _(msg`Produk tidak tersedia`)
    }

    if (isAfterSaleAt) {
      // product past sale date
      saleCountdown.label = _(msg`Produk sudah tidak tersedia`)
    } else if (isBeforeSaleAt) {
      // product before sale date
      saleCountdown.label = _(msg`Produk belum tersedia`)
    }

    // special case for "sold out" product, due to transaction queue
    if (showcase?.isSoldOut) {
      saleCountdown = {
        color: 'error',
        label: _(msg`Terjual habis`)
      }

      if (showcase.transactionPaidCount < product.maxRegistrant) {
        saleCountdown = {
          color: 'warning',
          label: _(msg`Menunggu antrian`)
        }
      }
    }
  } else {
    // product available
    saleCountdown = {
      color: 'success',
      label: _(msg`Aktif`)
    }

    if (endSaleAt) {
      // product available until endSaleAt
      const saleEnd = DateTime.fromISO(endSaleAt)

      saleCountdown = _(
        msg`Tersedia sampai ${saleEnd.toLocaleString(DateTime.DATE_FULL)}`
      )
    }
  }

  let priceLabel = formatIDR(price)
  if (
    product.priceType === 'minimum' ||
    product.priceType === 'tipping' ||
    product.priceType === 'donation'
  ) {
    priceLabel = 'Min. ' + priceLabel
  }

  //
  const isShowCarouselNavigation = highlightImages.length > 1

  return (
    <Card>
      <GridCarousel
        isPersistEdgeNavOnMobile={isShowCarouselNavigation}
        isEnableDotNav={isShowCarouselNavigation}
        isEnableEdgeNav={isShowCarouselNavigation}
      >
        {highlightImages.map(({ imageURL, linkURL }, imageNo) =>
          linkURL ? (
            <ImageLink
              key={`image-no-${imageNo}`}
              href={linkURL}
            >
              <Image
                src={imageURL ?? fallbackImage.grey300Pixel}
                alt={`Highlight image ${imageNo + 1} for ${name}`}
                loading='eager'
              />
            </ImageLink>
          ) : (
            <div key={`image-no-${imageNo}`}>
              <Image
                src={imageURL ?? fallbackImage.grey300Pixel}
                alt={`Highlight image ${imageNo + 1} for ${name}`}
              />
            </div>
          )
        )}
      </GridCarousel>

      <CardContent sx={{ paddingTop: isShowCarouselNavigation ? 2 : 3 }}>
        <Stack spacing={1}>
          <Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
            {name}
          </Typography>

          {subtitle && (
            <Typography sx={{ color: 'text.secondary' }}>{subtitle}</Typography>
          )}

          <div>
            <Grid
              container
              spacing={1}
            >
              <Grid
                item
                xs='auto'
              >
                <Chip
                  color='info'
                  size='small'
                  label={priceLabel}
                />
              </Grid>

              {priceBeforeDiscount && (
                <Grid
                  item
                  xs='auto'
                >
                  <Typography
                    component='span'
                    variant='shy'
                    sx={{
                      textDecoration: 'line-through',
                      color: 'text.secondary',
                      fontSize: '0.7rem'
                    }}
                  >
                    {formatIDR(priceBeforeDiscount)}
                  </Typography>
                </Grid>
              )}

              {saleCountdown && (
                <Grid
                  item
                  xs='auto'
                >
                  <Chip
                    size='small'
                    label={saleCountdown.label}
                    color={saleCountdown.color}
                  />
                </Grid>
              )}
            </Grid>
          </div>

          {isShowTransactionCount && (
            <Typography
              color='text.secondary'
              fontSize='small'
            >
              {_(msg`${showcase?.transactionPaidCount ?? 0} transaksi`)}

              {maxRegistrant &&
                maxRegistrant > 0 &&
                showcase.transactionPendingCount &&
                showcase.transactionPendingCount > 0 &&
                _(msg`, ${showcase.transactionPendingCount} dalam antrian`)}
            </Typography>
          )}

          <TextWithSoftBreaks>{description}</TextWithSoftBreaks>
        </Stack>
      </CardContent>
    </Card>
  )
}
