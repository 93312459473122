// @mui
import { Box, Container } from '@mui/material'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @returns {JSX.Element}
 */
export default function ClientPageContainer({ children }) {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: 'pageBackground',
        paddingBottom: 8
      }}
    >
      <Container
        maxWidth='sm'
        disableGutters
      >
        <div>{children}</div>
      </Container>
    </Box>
  )
}
